import { DocumentCard, DocumentCardActions, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, DocumentCardType, IconButton, Image, ImageFit, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ExcelHelper } from "../../models/excel/excelHelper";
import { Translations } from "../../models/common/translations";
import AppTooltip from "../common/AppTooltip";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseBase64Content, removeUseCase } from "../../models/services/useCaseService";
import { callService, callServiceOnRemove, hasPermission, showConfirmationDialog } from "../../models/common/appUtils";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Base } from "../../framework/base";

//Styles
const StyledDocumentCard = styled(DocumentCard)`
    margin-bottom: 10px;
    cursor: pointer;
    
    &:hover {
        background-color: #e8e8e8;
    }
`

const StyledDocumentCardContent = styled.div`
    padding: 5px;
`

const StyledImage = styled(Image)`
    margin-right: 5px;
    
`
const StyledDocumentCardTitle = styled(Stack)`
    font-size: 20px;
    padding-bottom: 5px;
    align-items: center;
    
`

//Component
export interface IUseCaseListItemProps {
    item: IUseCaseListItem;
    refreshList: () => void;
}

export const UseCaseListItem = (props: IUseCaseListItemProps) => {
    const navigate = useNavigate();
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const authUser = useSelector((state: RootState) => state.auth.authUser);

    const handleOpenUseCaseExcel = async () => {
        if (!props.item) return;
        const content = await callService(() => getUseCaseBase64Content(props.item.id), false)
        if (!content) return;
        await ExcelHelper.createNewWorkbook(content);
    }

    const handleEdit = () => {
        navigate("/usecaseedit/" + props.item.id);
    }

    const handleRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.UseCaseRemoveConfirmation, props.item.name), Translations.RemoveUseCase);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeUseCase(props.item.id))
        if (!dbResult) return;
        props.refreshList();
    }

    return (
        <StyledDocumentCard onClick={handleOpenUseCaseExcel}>
            <AppTooltip
                text={props.item.tip}
            >
                <StyledDocumentCardContent>
                    <StyledDocumentCardTitle horizontal>
                        {!!props.item.image &&
                            <StyledImage
                                src={"data:image/jpg;base64," + props.item.image} 
                                height={50}
                                width={50}
                                imageFit={ImageFit.cover}
                            />
                        }
                        <div>{props.item.name}</div>
                    </StyledDocumentCardTitle>
                    {!!props.item.description &&
                        <Stack horizontal>
                            <div>{props.item.description}</div>
                        </Stack>
                    }
                </StyledDocumentCardContent>
                {managementMode && hasPermission(authUser, AuthorizationLevel.User) &&
                    <Stack horizontal horizontalAlign="end" onClick={(e) => e.stopPropagation()}>
                        <IconButton iconProps={{ iconName: "Edit" }} title={Translations.Edit} ariaLabel={Translations.Edit} onClick={handleEdit} />
                        <IconButton iconProps={{ iconName: "Remove" }} title={Translations.Remove} ariaLabel={Translations.Remove} onClick={handleRemove} />
                    </Stack>
                }
            </AppTooltip>
        </StyledDocumentCard>
    );
}

export default UseCaseListItem;
