import styled from "@emotion/styled";
import { Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";

//Style
const StyledSpinner = styled(Spinner)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1001;
`

//Component
export const LoadingIndicator = (props: { text: string }) => {
  return (
    <StyledSpinner size={SpinnerSize.large} label={props.text} />
  );
}

export default LoadingIndicator;