import { IconButton } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../common/TitleBar";
import { Translations } from "../../models/common/translations";
import UseCaseListItem from "./UseCaseListItem";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseListItems } from "../../models/services/useCaseService";
import { callService, useCaseCanBeAdded } from "../../models/common/appUtils";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Base } from "../../framework/base";

//Styles
const GuidTextDiv = styled.div`
    padding-bottom: 10px;
`

//Component
export const UseCaseList = () => {
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseListItems, setUseCaseListItems] = useState<IUseCaseListItem[]>([]);

    const loadInitialData = async () => {
        const newUseCaseListItems = await callService(() => getUseCaseListItems())
        if (!newUseCaseListItems) return
        setUseCaseListItems(newUseCaseListItems.items);
    }

    useEffect(() => {
        loadInitialData();
    }, [authUser?.ownerCode]);

    const refreshList = async () => {
        await loadInitialData();
    }

    const handleAddUseCase = () => {
        navigate("/usecaseedit/" + Base.emptyGuid);
    }
        
    return (
        <div>
            <TitleBar
                title={Translations.UseCases}
                menuItems={[
                    {
                        key: "refresh",
                        text: Translations.RefreshList,
                        iconProps: { iconName: "Refresh" },
                        onClick: () => {
                            refreshList();
                            return true;
                        }
                    }
                ].concat(useCaseCanBeAdded(authUser) && managementMode
                    ? [
                        {
                            key: "add",
                            text: Translations.AddUseCase,
                            iconProps: { iconName: "Add" },
                            onClick: () => {
                                handleAddUseCase();
                                return true;
                            }
                        }
                    ]
                    : [])}
            >
            </TitleBar>
            <div>
                <GuidTextDiv>{Translations.OpenSampleDataFile}</GuidTextDiv>
                <div>
                    {useCaseListItems.map((item) =>
                        <UseCaseListItem
                            key={item.id}
                            item={item}
                            refreshList={refreshList}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default UseCaseList;