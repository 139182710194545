import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem, IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import { ITemplate } from "../../models/template/template";
import { templateActions } from "../../store/template";
import { callServiceOnRemove, hasPermission, navigateWithSetReturnUrl, showConfirmationDialog, templateCanBeCopied, templateCanBeRemoved, templateStateCanBeChanged } from "../../models/common/appUtils";
import { removeTemplate } from "../../models/services/templateService";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";
import { useSelector } from "react-redux";
import { Base } from "../../framework/base";
import { appTheme } from "../appTheme";

//Styles
const TemplateDiv = styled.div(
    {
        width: 131,
        marginLeft: 2,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
    }
)

const Thumbnail = styled.div(
    {
        height: 185,
        marginBottom: 5,
        display: "flex",
        alignItems: "center",

        "&.withText": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 185,
            border: "1px solid #333"
        },

        img: {
            border: "1px solid #333",
            objectFit: "contain",
            width: "100%"
        },

        div: {
            textAlign: "center"
        }                    
    }
)

const TemplateNameRow = styled.div`
    display: flex;
`

const TemplateName = styled.div`
    word-break: break-all;
    flex-grow: 1;
`

//Component
interface IUseCaseListItemProps {
    useCaseId: string;
    template: ITemplate;
}

export const UseCaseTemplateListItem = (props: IUseCaseListItemProps) => {
    const buttonRef = React.useRef(null);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const template = useSelector((state: RootState) => state.template.template);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = React.useState<IContextualMenuItem[]>([]);

    const handleSelectTemplate = () => {
        if (!props.template) return;
        dispatch(templateActions.setTemplate(props.template));
        //navigate("/usecasetemplate");
    }
    
    const handleEditTemplate = () => {
        navigateWithSetReturnUrl(navigate, "/templateedit/" + props.template.code, "/usecaseopen/" + props.useCaseId);
    }

    const removeTemplateFromDb = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.TemplateRemoveConfirmation, props.template.name), Translations.RemoveTemplate);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeTemplate(props.template.id))
        if (!dbResult) return;
        dispatch(templateActions.setTemplateListId(templateListId + 1));
    }

    const handleRemoveTemplate = () => {
        removeTemplateFromDb();
    }

    const handleCopyTemplate = () => {
        dispatch(templateActions.setTemplateCopy({ template: props.template, returnUrl: "/usecaseopen/" + props.useCaseId }));
        navigate("/templatecopy");
    }

    const handleMore = () => {
        const items: IContextualMenuItem[] = [{ key: "Preview", iconProps: { iconName: "Preview" }, text: Translations.Preview, onClick: handleSelectTemplate }];
        if (hasPermission(authUser, AuthorizationLevel.Reader)) {
            items.push({ key: "Divider", itemType: ContextualMenuItemType.Divider });
            if (templateCanBeCopied(authUser, props.template)) {
                items.push({ key: "Copy", iconProps: { iconName: "Copy" }, text: Translations.Copy, onClick: handleCopyTemplate });
            }
            const canEdit = templateStateCanBeChanged(authUser, props.template);
            items.push({ key: "Edit", iconProps: { iconName: canEdit ? "Edit" : "OpenFolderHorizontal" }, text: canEdit ? Translations.Edit : Translations.Open, onClick: handleEditTemplate });
            if (templateCanBeRemoved(authUser, props.template)) {
                items.push({ key: "Remove", iconProps: { iconName: "Remove" }, text: Translations.Remove, onClick: handleRemoveTemplate });
            }
        }
        setMenuItems(items);
    }

    const onHideContextMenu = React.useCallback(() => setMenuItems([]), []);

    const selectedStyle = React.useCallback(() => { return template?.id === props.template?.id ? { boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground } : null; }, [props.template?.id, template?.id]);

    return (
        <TemplateDiv onClick={() => handleSelectTemplate()}>
            <Thumbnail className={props.template.thumbnail ? "" : " withText"} style={props.template.thumbnail ? null : selectedStyle()}>{props.template.thumbnail
                ? <img src={"data:image/jpg;base64," + props.template.thumbnail} alt={Translations.Thumbnail} style={selectedStyle()}></img>
                : <div><span>{props.template.thumbnailText}</span></div>}
            </Thumbnail>
            <TemplateNameRow>
                <TemplateName title={props.template.name}>{props.template.name}</TemplateName>
                {managementMode &&
                    <div onClick={(e) => e.stopPropagation()} ref={buttonRef}><IconButton iconProps={{ iconName: "More" }} title={Translations.Edit} ariaLabel={Translations.Edit} onClick={handleMore} /></div>
                }
            </TemplateNameRow>
            {managementMode &&
                <ContextualMenu
                    items={menuItems}
                    hidden={menuItems.length < 1}
                    target={buttonRef}
                    onItemClick={onHideContextMenu}
                    onDismiss={onHideContextMenu}
                />
            }
        </TemplateDiv>
    );
}

export default UseCaseTemplateListItem;