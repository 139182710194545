import * as React from "react";
import styled from "@emotion/styled";
import { ITemplateGroup } from "../../models/templateGroup/templateGroup";
import { Translations } from "../../models/common/translations";
import UseCaseTemplateListItem from "./UseCaseTemplateListItem";
import { ContextualMenu, IContextualMenuItem, IconButton } from "@fluentui/react";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { templateActions } from "../../store/template";
import PageSubTitle from "../common/PageSubTitle";

//Styles
const TemplateGroup = styled.div(
    {
        width: "100%",
    }
)

const Templates = styled.div(
    {
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        flexFlow: "row wrap",
        cursor: "pointer",
    }
)

const TitleRow = styled.div`
    display: flex;
`

//Component
export interface IUseCaseTemplateGroupsListProps {
    useCaseId: string;
    templateGroups: ITemplateGroup[];
    height: string;
}

export const UseCaseTemplateGroupsList = (props: IUseCaseTemplateGroupsListProps) => {
    const buttonRef = React.useRef(null);
    const dispatch = useAppDispatch();
    const [menuItems, setMenuItems] = React.useState<IContextualMenuItem[]>([]);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);

    const handleShowHideArchived = () =>  {
        dispatch(templateActions.setShowArchived(!showArchived));
    }

    const handleMore = () => {
        setMenuItems( [
            { key: "showHideArchived", iconProps: { iconName: showArchived ? "Hide" : "RedEye" }, text: showArchived ? Translations.HideHidden : Translations.ShowHidden, onClick: handleShowHideArchived }
          ])
    }

    const onHideContextMenu = React.useCallback(() => setMenuItems([]), []);

    return (
        <React.Fragment>
            <PageSubTitle
                text={Translations.SelectTemplate}
                suffix={managementMode ? <div onClick={(e) => e.stopPropagation()} ref={buttonRef}><IconButton iconProps={{ iconName: "More" }} title={Translations.More} ariaLabel={Translations.More} onClick={handleMore} /></div> : null}
            />
            <div style={{ height: props.height, overflowY: "auto", overflowX: "hidden" }}>
                {props.templateGroups.map((templateGroup) =>
                    <TemplateGroup key={templateGroup.code}>
                        <h4>{templateGroup.name}</h4>
                        <Templates>
                            {templateGroup.templates.map((template) =>
                                <UseCaseTemplateListItem
                                    key={template.code}
                                    useCaseId={props.useCaseId}
                                    template={template}
                                />
                            )}
                        </Templates>
                    </TemplateGroup>
                )}
            </div>
            {managementMode &&
                <ContextualMenu
                    items={menuItems}
                    hidden={menuItems.length < 1}
                    target={buttonRef}
                    onItemClick={onHideContextMenu}
                    onDismiss={onHideContextMenu}
                />            
            }
        </React.Fragment>
    );
}

export default UseCaseTemplateGroupsList;