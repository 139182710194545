import * as React from "react";
import { MessageBarType } from "@fluentui/react";
import { Translations } from "../models/common/translations";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { appActions } from "../store/app";
import styled from "@emotion/styled";
import Message from "./common/Message";
import { Login } from "./login/Login";
import Progress from "./common/Progress";
import LoadingIndicator from "./common/LoadingIndicator";
import AppSettings from "./settings/AppSettings";
import Dialog from "./common/Dialog";
import UseCaseList from "./useCase/UseCaseList";
import UseCaseEdit from "./useCase/UseCaseEdit";
import UseCaseOpen from "./useCase/UseCaseOpen";
import { ApplicationSettings } from "../models/common/applicationSettings";
import { navigateWithSetReturnUrl } from "../models/common/appUtils";
import { SelectOwner } from "./owner/SelectOwner";
import TemplateEdit from "./template/TemplateEdit";
import { TemplateCopy } from "./template/TemplateCopy";
import { PrivateRoute } from "./routeFilters/privateRoute";
import { OwnerRoute } from "./routeFilters/OwnerRoute";
import { AuthorizationLevelUserRoute } from "./routeFilters/AuthorizationLevelUserRoute";
import { AuthorizationLevelReaderRoute } from "./routeFilters/AuthorizationLevelReaderRoute";
import { DefaultRoute } from "./routeFilters/DefaultRoute";
import UseCaseDataSetOpen from "./useCase/UseCaseDataSetOpen";
import Home from "./home/Home";

/* global console, Excel, require  */

//Styles
const TaskPaneContent = styled.div(
    {
        padding: 10
    }
)

//App
export interface IAppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export const App = (props: IAppProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const spinner = useSelector((state: RootState) => state.app.spinner);
    const error = useSelector((state: RootState) => state.app.error);
    const success = useSelector((state: RootState) => state.app.success);
    const dialog = useSelector((state: RootState) => state.app.dialog);

    const loadInitialData = async () => {
        if (!ApplicationSettings.settingsAreSpecified()) {
            navigateWithSetReturnUrl(navigate, "/settings", "/login");
        } else {
            navigate("/default");
        }
    };

    useEffect(() => {
        loadInitialData();
    }, []);

    const hideDialog = () => {
        dispatch(appActions.hideDialog());
    }

    if (!props.isOfficeInitialized) {
        return (
            <Progress
                title={props.title}
                logo={require("./../../../assets/icon-128.png")}
                message="Please sideload your addin to see app body."
            />
        );
    }

    return (
        <TaskPaneContent>
            {spinner.show &&
                <LoadingIndicator text={spinner.text} />
            }
            <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/settings" element={<AppSettings />}/>
                <Route path="/default" element={<DefaultRoute />}/>
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/selectowner" element={<SelectOwner />}/>
                    <Route path="/" element={<OwnerRoute />}>
                        <Route path="/" element={<Home />}/>
                        <Route path="/usecaselist" element={<UseCaseList />}/>
                        <Route path="/usecaseopen/:id" element={<UseCaseOpen />}/>
                        <Route path="/usecasedatasetopen" element={<UseCaseDataSetOpen />}/>
                        <Route path="/" element={<AuthorizationLevelReaderRoute />}>
                            <Route path="/templateedit/:code" element={<TemplateEdit />}/>
                            <Route path="/" element={<AuthorizationLevelUserRoute />}>
                                <Route path="/usecaseedit/:id" element={<UseCaseEdit />}/>
                                <Route path="/templatecopy" element={<TemplateCopy />}/>
                            </Route>                
                        </Route>                
                    </Route>                
                </Route>                
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {dialog.show &&
                <Dialog
                    dialogType={dialog.type}
                    title={dialog.title}
                    body={dialog.text}
                    buttons={dialog.buttons ?? [{ title: Translations.Close, onClick: hideDialog }]}
                    onClose={hideDialog}
                />
            }
            {!!error.text &&
                <Message
                    text={error.text}
                    type={MessageBarType.error}
                />
            }
            {!!success.text &&
                <Message
                    text={success.text}
                    type={MessageBarType.success}
                />
            }
        </TaskPaneContent>
    );
}

export default App;