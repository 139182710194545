import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUseCaseEditItem } from "../../models/useCase/useCaseEditItem";
import { IUseCaseDataSetListItem } from "../../models/useCaseDataSet/useCaseDataSetListItem";
import { INameValue } from "../../models/common/nameValue";

export interface IUseCaseState {
    useCase: IUseCaseEditItem | null;
    useCaseDataSetOpen: { useCaseDataSet: IUseCaseDataSetListItem, generationParameters: INameValue[] } | null
}

const useCaseSlice = createSlice({
    name: "usecase",
    initialState: createInitialState(),
    reducers: {
        //UseCase
        setUseCase(state: IUseCaseState, action: PayloadAction<IUseCaseEditItem>) {
            state.useCase = { ...action.payload }
        },
        //UseCaseDataSet
        setUseCaseDataSetOpen(state: IUseCaseState, action: PayloadAction<{ useCaseDataSet: IUseCaseDataSetListItem, generationParameters: INameValue[] }>) {
            state.useCaseDataSetOpen = { ...action.payload }
        },
    },
});

function createInitialState(): IUseCaseState {
    return {
        useCase: null,
        useCaseDataSetOpen: null
    }
}

export const useCaseActions = { ...useCaseSlice.actions };
export const useCaseAReducer = useCaseSlice.reducer;
export default useCaseSlice.reducer;