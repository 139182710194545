import * as React from "react";
import { Dropdown, IDropdownProps } from "@fluentui/react";
import { Control, Controller, UseControllerProps } from "react-hook-form";

//Component
export interface IFormDropdownProps {
  label?: string;
  name: string;
  control: Control<any>;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const FormDropdown = (props: IFormDropdownProps & IDropdownProps) => {
  return (
    <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || ""} render={({ field, fieldState }) => (
      <Dropdown
        {...props}
        {...field}
        id={field.name}
        label={props.label}
        title={props.label}
        selectedKey={field.value}
        onChange={(_e, option) => {
          field.onChange(option.key);
        }}
        onBlur={field.onBlur}
        errorMessage={fieldState.error ? fieldState.error.message : ""}
      />
    )}
    />
  );
}

export default FormDropdown;