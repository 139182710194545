import { IconButton, Label, TextField } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import { useEffect, useState } from "react";
import { IParameterData } from "../../models/excel/parameterData";
import { ParameterDataReader } from "../../models/excel/parameterDataReader";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import { showErrorMessage, showSuccessMessage } from "../../models/common/appUtils";
import { templateActions } from "../../store/template";
import PageSubTitle from "../common/PageSubTitle";

//Style
const ParameterDataDiv = styled.div(
    {
        ".error": {
            marginBottom: 15,

            "div": {
                color: "tomato"
            }
        },

        ".errorData": {
            color: "tomato",
            whiteSpace: "pre-wrap",
            marginBottom: 15
        },

        ".data": {
            whiteSpace: "pre-wrap"
        }
    }
)

//Component
export interface IUseCaseParametersProps {
    height: string;
    onRefreshParameters: () => void;
}

export const UseCaseParameters = (props: IUseCaseParametersProps) => {
    const parameterDatas = useSelector((state: RootState) => state.template.parameterDatas);

    useEffect(() => {
        props.onRefreshParameters();
    }, [props.height]);

    const handleCopyToClipboard = (data: string) => {
        navigator.clipboard.writeText(data)
            .then(() => {
                showSuccessMessage(Translations.ParameterDataCopiedToClipboard);
            }, (error) => {
                console.log("handleCopyToClipboard", error);
                showErrorMessage(Translations.ParameterDataCopyingToClipboardFailed);
            });
    }

    return (
        <div>
            {parameterDatas.map((parameterData, index) =>
                <ParameterDataDiv key={index}>
                    <PageSubTitle
                        text={Translations.GeneratedXMLdata + (parameterDatas.length > 1 ? " " + parameterData.filename : "")}
                        suffix={<>
                            <IconButton iconProps={{ iconName: "Refresh" }} title={Translations.RefreshList} ariaLabel={Translations.RefreshList} onClick={props.onRefreshParameters} />
                            {!parameterData.error &&
                                <IconButton iconProps={{ iconName: "Copy" }} title={Translations.Copy} ariaLabel={Translations.Copy} onClick={() => handleCopyToClipboard(parameterData.data)} />
                            }
                        </>}
                    />
                    {!!parameterData.error &&
                        <div style={{ height: props.height, overflowY: "auto", overflowX: "hidden" }}>
                            <div className="error" dangerouslySetInnerHTML={{ __html: parameterData.error }}>
                            </div>
                            <div className="errorData">
                                {parameterData.errorData}
                            </div>
                            <h3>{Translations.GeneratedXMLdata}</h3>
                            <div className="data">
                                {parameterData.data}
                            </div>
                        </div>
                    }
                    {!parameterData.error &&
                        <TextField
                            style={{ height: props.height, overflowY: "auto", overflowX: "hidden" }}
                            multiline
                            readOnly={true}
                            value={parameterData.data}
                        />
                    }
                </ParameterDataDiv>
            )}
        </div>
    );
}

export default UseCaseParameters;