import { IContextualMenuItem, Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { ITemplateGroup, TemplateGroup } from "../../models/templateGroup/templateGroup";
import { getTemplateGroups, getThumbnail } from "../../models/services/templateService";
import { AuthorizationLevel, LanguageCode, TemplateStateCode, TemplateThumbnailSize } from "../../models/common/enums";
import { Translations } from "../../models/common/translations";
import { appActions } from "../../store/app";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";
import TitleBar from "../common/TitleBar";
import { useSelector } from "react-redux";
import { getUseCaseDataSetListItems } from "../../models/services/useCaseDataService";
import { callService, hasPermission, navigateWithSetReturnUrl, showErrorMessage } from "../../models/common/appUtils";
import { getUseCaseEdit } from "../../models/services/useCaseService";
import { useCaseActions } from "../../store/useCase";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import { UseCaseEdit } from "../../models/useCase/useCaseEdit";
import { IUseCaseEditItem } from "../../models/useCase/useCaseEditItem";
import UseCaseTemplateGroupsList from "./UseCaseTemplateGroupsList";
import { IUseCaseDataSetListItem } from "../../models/useCaseDataSet/useCaseDataSetListItem";
import { pivotStyles } from "../appTheme";
import UseCaseParameters from "./UseCaseParameters";
import UseCaseTemplate from "./UseCaseTemplate";
import PageSubTitle from "../common/PageSubTitle";
import { templateActions } from "../../store/template";
import { Base } from "../../framework/base";
import { IParameterData } from "../../models/excel/parameterData";
import { ParameterDataReader } from "../../models/excel/parameterDataReader";
import { forEach } from "jszip";

//Styles
const TemplateGroups = styled.div(
    {
        width: "100%",
    }
)

const UseCaseDescription = styled.div`
    max-height: 90px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
`

const UseCaseDataSetContainer = styled.div`
     max-height: 90px;
     overflow-y: auto;
     overflow-x: hidden;
     border: 1px solid #605E5C;
     cursor: pointer;
 `

const UseCaseDataSetLine = styled.div`
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
        background-color: #F3F2F1;
    }
 `

//Component
export const UseCaseOpen = () => {
    const mounted = useRef(false);
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseDataSets, setUseCaseDataSets] = useState<IUseCaseDataSetListItem[]>([]);
    const [templateGroups, setTemplateGroups] = useState<ITemplateGroup[]>([]);
    const [filteredTemplateGroups, setFilteredTemplateGroups] = useState<ITemplateGroup[]>([]);
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([]);
    const [descriptionContainerHeight, setDescriptionContainerHeight] = useState(0);
    const [getDataContainerHeight, setGetDataContainerHeight] = useState(0);
    
    const loadThumbnail = async (oldTemplateGroups: ITemplateGroup[], templateGroupCode: string, templateCode: string) => {
        try {
            const thumnailResult = await getThumbnail(templateCode, TemplateThumbnailSize.Medium);
            const templateGroup = oldTemplateGroups.find(i => i.code === templateGroupCode);
            if (!templateGroup) return;
            const template = templateGroup.templates.find(i => i.code === templateCode);
            if (!template) return;
            if (thumnailResult.thumbnail) {
                template.thumbnail = thumnailResult.thumbnail;
            } else {
                template.thumbnailText = Translations.ThumbnailIsNotAvailable;
            }
            if (!mounted) return;
            setTemplateGroups([...oldTemplateGroups]);
        } catch (error) {
            console.log("loadThumbnail", error);
        }
    }

    const modifyFilteredTemplateGroups = () => {
        const result: ITemplateGroup[] = [];
        for (const templateGroup of templateGroups) {
            const templates = templateGroup.templates.filter(i => showArchived || i.stateCode !== TemplateStateCode.Archived);
            if (!templates.length) continue;
            const newTemplateGroup = new TemplateGroup();
            newTemplateGroup.templates = templates;
            result.push(newTemplateGroup);
        }
        setFilteredTemplateGroups(result);
    }

    useEffect(() => {
        modifyFilteredTemplateGroups();
    }, [templateGroups, showArchived]);

    const loadTemplateGroups = async (useCaseParam: IUseCaseEditItem) => {
        dispatch(appActions.showSpinner());
        try {
            const generationParameters = new UseCaseGenerationParameters(useCaseParam.generationParameters);
            const newTemplateGroups = await getTemplateGroups(LanguageCode.Fi, generationParameters.templateGroup);
            if (!mounted || !newTemplateGroups) return;
            setTemplateGroups(newTemplateGroups.items);
            setFilteredTemplateGroups(newTemplateGroups.items);
            if (newTemplateGroups.items && newTemplateGroups.items[0].templates) {
                dispatch(templateActions.setTemplate(newTemplateGroups.items[0].templates[0]));
            }
            for (const templateGroup of newTemplateGroups.items) {
                for (const template of templateGroup.templates) {
                    if (!mounted) break;
                    await loadThumbnail(newTemplateGroups.items, templateGroup.code, template.code);
                }
            }
        } catch (error) {
            console.log("loadTemplateGroups", error);
            dispatch(appActions.showError(error.toString()));
        } finally {
            dispatch(appActions.hideSpinner());
        }
    }

    const loadUseCaseDataSets = async (useCaseParam: IUseCaseEditItem) => {
        const items = await callService(() => getUseCaseDataSetListItems(useCaseParam.id), false)
        if (!items) return
        setUseCaseDataSets(items.items);
    }

    const loadUseCase = async (): Promise<UseCaseEdit> => {
        const result = await callService(() => getUseCaseEdit(id))
        if (!result) return null;
        dispatch(useCaseActions.setUseCase(result.item));
        return result;
    }

    const loadData = async () => {
        const useCaseEdit = await loadUseCase();
        if (!useCaseEdit) return;
        loadUseCaseDataSets(useCaseEdit.item);
        loadTemplateGroups(useCaseEdit.item);
    }

    useEffect(() => {
        setMenuItems([
            {
                key: "refresh",
                text: Translations.RefreshList,
                iconProps: { iconName: "Refresh" },
                onClick: () => {
                    loadTemplateGroups(useCase);
                    return true;
                }
            }
        ].concat(hasPermission(authUser, AuthorizationLevel.User) && managementMode
            ? [
                {
                    key: "edit",
                    text: Translations.Edit,
                    iconProps: { iconName: "Edit" },
                    onClick: () => {
                        openUseCase();
                        return true;
                    }
                }
            ]
            : []));
    }, [authUser?.authorizationLevel ?? 9, managementMode]);

    useEffect(() => {
        loadData();
    }, [templateListId]);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const openUseCase = async () => {
        const settings = await ExcelFileSettings.readSettings();
        navigateWithSetReturnUrl(navigate, "/usecaseedit/" + settings.useCaseId, "/usecaseopen/" + settings.useCaseId);
    }

    const handleOpenUseCaseDataSet = async (useCaseDataSet: IUseCaseDataSetListItem) => {
        const settings = await ExcelFileSettings.readSettings();
        dispatch(useCaseActions.setUseCaseDataSetOpen({ useCaseDataSet, generationParameters: useCase.generationParameters }));
        navigateWithSetReturnUrl(navigate, "/usecasedatasetopen", "/usecaseopen/" + settings.useCaseId);
    }

    // const onSaveExcelFile = () => {
    //     ExcelHelper.getDocumentAsBase64((bytes: Uint8Array, error: string) => {
    //         if (!bytes?.length) {
    //             if (error) {
    //                 showErrorMessage(Translations.UseCaseExcelFileSaveFailed + " " + Translations.Error + ": '" + error + "'")
    //             }
    //             return;
    //         }
    //         const saveData = {
    //             id: useCase.id,
    //             rowId: useCase.rowId,
    //             file: Base64.fromUint8Array(bytes),
    //             fileName: null,
    //             modifyDate: null
    //         };
    //         callServiceOnSave(() => saveUseCaseExcel(saveData));
    //     });
    // }
    
    const handleDescriptionContainerMount = useCallback((node: HTMLDivElement) => {
        if (!node) return;
        setDescriptionContainerHeight(node?.getBoundingClientRect().height);
    }, []);

    const handleGetDataMount = useCallback((node: HTMLDivElement) => {
        if (!node) return;
        setGetDataContainerHeight(node?.getBoundingClientRect().height);
    }, []);

    const refreshParameters = async () => {
        const parameterDataReader = new ParameterDataReader();
        const generationParameters = new UseCaseGenerationParameters(useCase.generationParameters);
        const result = await parameterDataReader.getParameterDatas(generationParameters.rootElementName,
            generationParameters.omitElementsWithPrefix, generationParameters.useCollectionElements);
        if (result.error) {
            showErrorMessage(Translations.ParameterDataReadingFailed);
        } else {
            const parseXmlErrors = result.data.filter(i => i.errorData);
            if (parseXmlErrors.length) {
                showErrorMessage(Translations.ParameterDataValidationFailed);
            }
        }
        for (const data of result.data) {
            data.id = Base.getGuid();
        }
        dispatch(templateActions.setParameterDatas(result.data));
    }

    return (
        <TemplateGroups>
            <TitleBar
                title={Translations.UseCase}
                subTitle={useCase?.name}
                menuItems={menuItems}
            >
            </TitleBar>
            {!!useCase?.description &&
                <div ref={handleDescriptionContainerMount}>
                    <UseCaseDescription>{useCase.description}</UseCaseDescription>
                </div>
            }
            <Pivot
                styles={pivotStyles}
            >
                <PivotItem headerText={Translations.Data} >
                    {!!useCaseDataSets.length &&
                        <div ref={handleGetDataMount}>
                            <PageSubTitle
                                text={Translations.GetData}
                            />                            
                            <UseCaseDataSetContainer>
                                {useCaseDataSets.map(useCaseDataSet =>
                                    <UseCaseDataSetLine key={useCaseDataSet.id} onClick={() => handleOpenUseCaseDataSet(useCaseDataSet)}>{useCaseDataSet.name}</UseCaseDataSetLine>
                                )}
                            </UseCaseDataSetContainer>
                        </div>
                    }
                    <UseCaseParameters
                        height={"calc(100vh - " + (250 + descriptionContainerHeight + getDataContainerHeight).toString(10) + "px)"}
                        onRefreshParameters={refreshParameters}
                    />
                </PivotItem>
                <PivotItem headerText={Translations.Template}>
                    <UseCaseTemplateGroupsList
                        useCaseId={id}
                        templateGroups={filteredTemplateGroups}
                        height={"calc(100vh - " + (250 + descriptionContainerHeight).toString(10) + "px)"}
                    />
                </PivotItem>
                <PivotItem headerText={Translations.Preview}>
                    <UseCaseTemplate
                        onRefreshParameters={refreshParameters}
                    />
                </PivotItem>
            </Pivot>
        </TemplateGroups>
    );
}

export default UseCaseOpen;