import { IconButton, Label, Stack, Dropdown, IDropdownOption, ResponsiveMode, TextField, ActionButton, CommandButton, IContextualMenuProps, MessageBar, MessageBarType } from "@fluentui/react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as React from "react";
import { DocumentCreateResultBase64, IDocumentCreateResultBase64 } from "../../models/document/documentCreateResultBase64";
import { IDocumentCreateWithPreviewResult } from "../../models/document/documentCreateWithPreviewResult";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import { DocumentFormat, LanguageCode } from "../../models/common/enums";
import { useEffect, useState } from "react";
import ImageCarousel, { IImageCarouselImage } from "../common/ImageCarousel";
import { appActions } from "../../store/app";
import { getDocumentWithPreview } from "../../models/services/templateService";
import { Base } from "../../framework/base";
import { docxContentType, pdfContentType, xlsxContentType } from "../../models/common/consts";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import PageSubTitle from "../common/PageSubTitle";

//Style

//Component
interface IGenerationParams {
    format: DocumentFormat;
    formatName: string;
    languageCode: LanguageCode;
    languageName: string;
}

interface IGeneratedDocument extends IDocumentCreateResultBase64 {
    fileName: string;
}

class GeneratedDocument extends DocumentCreateResultBase64 implements IGeneratedDocument {
    fileName: string;

    constructor();
    constructor(obj: IGeneratedDocument);
    constructor(obj: IDocumentCreateWithPreviewResult);
    constructor(obj: IDocumentCreateResultBase64);
    constructor(obj?: any) {
        super(obj);
        this.fileName = obj && obj.fileName || "";
    }
}

interface IUseCaseTemplateProps {
    onRefreshParameters: () => void;
}

export const UseCaseTemplate = (props: IUseCaseTemplateProps) => {
    const mounted = React.useRef(false);
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const template = useSelector((state: RootState) => state.template.template);
    const parameterDatas = useSelector((state: RootState) => state.template.parameterDatas);
    const [generationParams, setGenerationParams] = useState<IGenerationParams>({ format: DocumentFormat.Pdf, formatName: DocumentFormat.Pdf.toUpperCase(), languageCode: LanguageCode.Fi, languageName: Translations.LanguageFinnish });
    const [documents, setDocuments] = useState<IGeneratedDocument[]>([]);
    const [thumbnails, setThumbnails] = useState<IImageCarouselImage[]>([]);
    const dispatch = useAppDispatch();

    const changeLanguageCode = (languageCode: LanguageCode, languageName: string) => {
        setGenerationParams(old => ({...old, languageCode, languageName }));
        handleRefreshPreview({...generationParams, languageCode, languageName });
    };

    const languageMenuProps: IContextualMenuProps = {
        items: [
            { key: LanguageCode.Fi, text: Translations.LanguageFinnish, onClick: () => { changeLanguageCode(LanguageCode.Fi, Translations.LanguageFinnish); return true; }},
            { key: LanguageCode.En, text: Translations.LanguageEnglish, onClick: () => { changeLanguageCode(LanguageCode.En, Translations.LanguageEnglish); return true; }},
            { key: LanguageCode.Sv, text: Translations.LanguageSwedish, onClick: () => { changeLanguageCode(LanguageCode.Sv, Translations.LanguageSwedish); return true; }}
        ]
    };

    const changeFormat = (format: DocumentFormat, formatName: string) => {
        setGenerationParams(old => ({...old, format, formatName }));
        handleRefreshPreview({...generationParams, format, formatName });
    };

    const formatsMenuProps: IContextualMenuProps = {
        items: template?.formats.map(i => {
            const code = i.toLowerCase() as DocumentFormat;
            const text = i.toUpperCase();
            return {
                key: code,
                text: text,
                onClick: () => {
                    changeFormat(code, text);
                    return true;
                }
            }}) ?? []
    };

    const handleRefreshPreview = async (params: IGenerationParams) => {
        if (!template) return;
        const errors: any[] = [];
        dispatch(appActions.showSpinner());
        let documents: IGeneratedDocument[] = [];
        let thumbnails: IImageCarouselImage[] = [];
        setDocuments(documents);
        setThumbnails(thumbnails);
        try {
            const generationParameters = new UseCaseGenerationParameters(useCase.generationParameters);
            for (const parameterData of parameterDatas) {
                if (!parameterData.errorData) {
                    try {
                        const result = await getDocumentWithPreview(generationParameters.context, template.code, params.languageCode, params.format, parameterData.data);
                        const generatedDocument = new GeneratedDocument(result);
                        generatedDocument.fileName = parameterData.filename + "." + params.format;
                        documents = documents.concat([generatedDocument]);
                        thumbnails = thumbnails.concat(result.pages.map(i => { return { id: Base.getGuid(), image: i, tooltip: generatedDocument.fileName } }));
                        setDocuments(documents);
                        setThumbnails(thumbnails);
                    } catch (error) {
                        console.log(error);
                        errors.push(error);
                    }
                }
            }
        } finally {
            dispatch(appActions.hideSpinner());
            setDocuments(documents);
            setThumbnails(thumbnails);
        }
        if (errors.length) {
            dispatch(appActions.showError(errors.map(i => i.toString()).join("\n")));
        }
    }

    const refreshPreview = () =>  {
        handleRefreshPreview(generationParams);
    }

    useEffect(() => {
        refreshPreview();
    }, [JSON.stringify(parameterDatas)]);

    useEffect(() => {
        mounted.current = true;
        refreshPreview();

        return () => {
            mounted.current = false;
        };
    }, []);

    const getContentType = (fileExt: string): string => {
        const lowExt = fileExt.toLowerCase();
        if (lowExt === "docx") {
            return docxContentType;
        }
        if (lowExt === "xlsx") {
            return xlsxContentType;
        }
        if (lowExt === "pdf") {
            return pdfContentType;
        }
        return "text/html";
    }

    const handleDownload = async () => {
        if (!documents.length) return;
        if (documents.length < 2) {
            const document = documents[0];
            saveAs(Base.base64ToBlob(document.document, getContentType(generationParams.format)), document.fileName);
        } else {
            const zip = new JSZip();
            for (const document of documents) {
                zip.file(document.fileName, document.document, { base64: true });
            }
            const blob = await zip.generateAsync({ type: "blob" });
            saveAs(blob, template.code + ".zip");
        }
    }

    if (!template) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={true}
            >
                {Translations.SelectTemplateForPreview}
          </MessageBar>
        );
    }

    return (
        <div>
            <PageSubTitle
                text={Translations.Preview}
                suffix={<IconButton iconProps={{ iconName: "Refresh" }} title={Translations.RefreshList} ariaLabel={Translations.RefreshList} onClick={props.onRefreshParameters} />}
            />
            <ImageCarousel
                images={thumbnails}
                footerSuffix={<>
                    <CommandButton text={generationParams.languageName} menuProps={languageMenuProps} />
                    <CommandButton text={generationParams.formatName} menuProps={formatsMenuProps} />
                </>}
                onDownload={handleDownload}
            />
        </div>
    );
}

export default UseCaseTemplate;