import { CommandButton, IContextualMenuProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import TitleBar from "../common/TitleBar";
import { useSelector } from "react-redux";
import { callService, callServiceOnSave, navigateWithSetReturnUrl } from "../../models/common/appUtils";
import { getOwnerListItems, setDefaultOwnerCode } from "../../models/services/ownerService";
import { IOwnerListItem } from "../../models/owner";
import { authActions } from "../../store/auth";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";

//Styles
const HomeContainer = styled.div`
    width: 100%,
`

const ImageContainer = styled.div`
    padding: 10px;
    padding-top: 30px;
`

const HomeButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`

//Component
export const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const [menuProps, setMenuProps] = useState<IContextualMenuProps>(null);
    const [canReturnToUseCase, setCanReturnToUseCase] = useState(false);
    
    const changeOwner = async (owner: IOwnerListItem) => {
        if (!owner) return;
        dispatch(authActions.setOwner(owner));
        await callServiceOnSave(() => setDefaultOwnerCode(owner.code), false);
    }

    const loadInitialData = async () => {
        if (!authUser.hasMultipleOwners) return;
        const newOwnerListItems = await callService(() => getOwnerListItems())
        if (!newOwnerListItems) return
        const newMenuProps: IContextualMenuProps = 
        {
            items: newOwnerListItems.items.map(i => {
                return {
                    key: i.code,
                    text: i.name,
                    onClick: () => {
                        changeOwner(i);
                        return true;
                    }
                }
            })
        };
        setMenuProps(newMenuProps);
    }

    const initializeOpenUseCaseData = async () => {
        const exelFileSettings = await ExcelFileSettings.readSettings();
        setCanReturnToUseCase(exelFileSettings.useCaseId && exelFileSettings.useCaseId === useCase?.id && useCase?.ownerCode === authUser.ownerCode);
    }
       
    useEffect(() => {
        initializeOpenUseCaseData();
    }, [authUser.ownerCode]);

    useEffect(() => {
        loadInitialData();
    }, []);

    const goToUseCaseList = () => {
        navigate("/usecaselist");
    }

    const goToUseCaseOpen = () => {
        navigate("/usecaseopen/" + useCase.id);
    };
    
    return (
        <HomeContainer>
            <TitleBar
                title={Translations.Home}
                menuItems={[{
                    key: "settings",
                    text: Translations.Settings,
                    iconProps: { iconName: "Settings" },
                    onClick: () => {
                        navigateWithSetReturnUrl(navigate, "/settings", "/");
                        return true;
                    }}]}
            >
                {!authUser.hasMultipleOwners &&
                    <span>{authUser.ownerName}</span>
                }
                {authUser.hasMultipleOwners &&
                    <CommandButton text={authUser.ownerName} menuProps={menuProps} />
                }
            </TitleBar>
            <ImageContainer>
                <svg width="212" height="221" viewBox="0 0 212 221" fill="none" style={{ width: "100%", height: "auto" }} xmlns="http://www.w3.org/2000/svg">
                    <path d="M212 106C212 152.39 182.203 191.817 140.702 206.188C123.998 211.939 106.126 213.453 88.6919 210.592C87.0373 210.322 85.3945 210.012 83.7635 209.661C81.5535 209.192 79.3696 208.652 77.2118 208.042C32.6566 195.502 0 154.564 0 106C0 47.4575 47.4575 0 106 0C164.542 0 212 47.4575 212 106Z" fill="#EEEEEE"/>
                    <path d="M39.6085 138.716C39.4219 138.816 39.2825 138.986 39.221 139.189C39.1595 139.391 39.1808 139.61 39.2803 139.797L79.1335 214.442C79.2333 214.629 79.4032 214.768 79.6057 214.83C79.8082 214.891 80.0269 214.87 80.2137 214.77L136.55 184.693C136.737 184.593 136.876 184.423 136.938 184.22C136.999 184.018 136.978 183.799 136.878 183.612L97.0251 108.967C96.9252 108.78 96.7554 108.641 96.5529 108.579C96.3504 108.518 96.1317 108.539 95.9449 108.638L39.6085 138.716Z" fill="#3F3D56"/>
                    <path d="M44.8451 140.898L81.3148 209.205L131.313 182.511L94.8436 114.203L44.8451 140.898ZM41.6769 142.589L41.6839 142.585L41.6761 142.589L41.6769 142.589Z" fill="white"/>
                    <path d="M57.4515 148.407C56.6748 148.822 56.5094 150.033 57.0823 151.106C57.6552 152.179 58.7531 152.715 59.5299 152.3L96.1485 132.749C96.9252 132.335 97.0906 131.124 96.5177 130.051C95.9448 128.978 94.8469 128.442 94.0702 128.857L57.4515 148.407Z" fill="#FBC010"/>
                    <path d="M61.9632 156.858C61.1865 157.272 61.0211 158.483 61.594 159.556C62.1669 160.629 63.2648 161.165 64.0416 160.75L100.66 141.2C101.437 140.785 101.602 139.575 101.029 138.502C100.457 137.429 99.3586 136.892 98.5819 137.307L61.9632 156.858Z" fill="#FBC010"/>
                    <path d="M66.4749 165.308C65.6981 165.723 65.5328 166.934 66.1056 168.007C66.6785 169.079 67.7764 169.616 68.5532 169.201L105.172 149.65C105.949 149.236 106.114 148.025 105.541 146.952C104.968 145.879 103.87 145.343 103.093 145.758L66.4749 165.308Z" fill="#FBC010"/>
                    <path d="M70.9866 173.759C70.2098 174.173 70.0445 175.384 70.6174 176.457C71.1902 177.53 72.2881 178.066 73.0649 177.651L109.684 158.101C110.46 157.686 110.626 156.475 110.053 155.402C109.48 154.329 108.382 153.793 107.605 154.208L70.9866 173.759Z" fill="#FBC010"/>
                    <path d="M75.4983 182.209C74.7215 182.624 74.5562 183.835 75.1291 184.907C75.702 185.98 76.7999 186.517 77.5766 186.102L114.195 166.551C114.972 166.137 115.137 164.926 114.564 163.853C113.992 162.78 112.894 162.244 112.117 162.659L75.4983 182.209Z" fill="#FBC010"/>
                    <path d="M82.6092 106.467L115.79 108.493L125.082 80.781L95.9793 74.6318L82.6092 106.467Z" fill="#2F2E41"/>
                    <path d="M106.539 99.4592C113.868 99.4592 119.81 93.5178 119.81 86.1888C119.81 78.8597 113.868 72.9183 106.539 72.9183C99.21 72.9183 93.2686 78.8597 93.2686 86.1888C93.2686 93.5178 99.21 99.4592 106.539 99.4592Z" fill="#FFB8B8"/>
                    <path d="M140.616 205.155L140.702 206.188C123.998 211.939 106.126 213.453 88.6918 210.592L83.7634 209.661L88.4532 199.26L91.5276 192.443L90.4207 168.857L90.3033 166.416L90.1586 163.303L92.1613 163.291L106.399 163.205L119.968 163.119L122.037 163.107C135.011 179.066 145.717 192.036 140.616 205.155Z" fill="#2F2E41"/>
                    <path d="M121.841 162.911L90.1587 166.432L88.9852 144.528C85.2671 135.045 87.7215 126.58 95.4382 118.985L97.007 112.775C97.4188 111.145 98.2695 109.659 99.4668 108.479C100.664 107.299 102.162 106.47 103.798 106.082C108.984 103.107 114.859 101.69 121.45 101.893C124.083 102.921 126.315 104.768 127.817 107.162C129.319 109.556 130.011 112.369 129.791 115.187C128.428 132.401 125.981 148.515 121.841 162.911Z" fill="#FBC010"/>
                    <path d="M96.5001 70.5098C96.5001 70.5098 89.6384 73.0138 89.4621 82.1176C89.4524 83.6496 89.6569 85.1754 90.0697 86.6507C90.5146 88.3238 90.8716 91.9541 87.8795 96.7934C85.9244 99.9346 84.1569 103.189 82.5861 106.538L90.3123 107.284L91.5899 94.476C92.2094 88.2664 93.7396 82.1818 96.1316 76.418L96.1816 76.2977C96.1816 76.2977 99.7262 82.4584 106.463 82.2686L103.989 79.9304C103.989 79.9304 112.427 83.1079 117.015 83.7459C117.539 83.8188 118.033 84.0332 118.443 84.3659C118.854 84.6987 119.166 85.1373 119.346 85.6344C119.526 86.1314 119.567 86.6682 119.465 87.1869C119.363 87.7056 119.121 88.1864 118.765 88.5777C118.738 88.6072 118.711 88.6368 118.683 88.6664C115.877 91.6349 110.292 98.5343 113.81 103.733C117.327 108.931 114.481 110.456 114.481 110.456L119.692 107.419L122.058 105.908L122.112 107.833L134.582 106.037C134.582 106.037 137.937 105.461 130.489 97.4838C130.489 97.4838 128.493 94.3608 129.25 91.5975C129.877 89.4959 129.994 87.2755 129.593 85.1195C127.722 76.1442 120.873 58.3884 96.5001 70.5098Z" fill="#2F2E41"/>
                    <path d="M99.5461 125.753L99.1395 128.272L94.8524 154.697L92.1614 163.291L91.2148 166.314L90.4209 168.857L80.7712 199.679L78.1237 205.899C76.6711 206.437 75.4226 207.414 74.553 208.696C73.6834 209.978 73.2361 211.499 73.2736 213.048C73.3111 214.596 73.8316 216.094 74.7623 217.332C75.6929 218.57 76.9873 219.486 78.4643 219.952C79.9412 220.419 81.527 220.412 82.9999 219.933C84.4727 219.453 85.759 218.526 86.6788 217.28C87.5985 216.034 88.1059 214.531 88.1298 212.982C88.1538 211.434 87.6932 209.916 86.8123 208.642L105.699 164.707L106.399 163.205L108.542 158.609L112.591 128.064L112.845 126.144L99.5461 125.753Z" fill="#FFB8B8"/>
                    <path d="M115.192 128.882C109.045 126.673 103.148 126.438 97.5904 128.882L99.4043 113.16C99.3378 111.196 100.044 109.285 101.372 107.838C102.7 106.39 104.543 105.521 106.504 105.418C108.49 105.313 110.437 106.002 111.915 107.332C113.393 108.663 114.282 110.526 114.387 112.512L114.388 112.537L115.192 128.882Z" fill="#FBC010"/>
                </svg>
            </ImageContainer>
            <HomeButtonsContainer>
                <PrimaryButton iconProps={{ iconName: "Add" }} text={Translations.NewDocument} onClick={goToUseCaseList} />
                {canReturnToUseCase &&
                    <PrimaryButton text={Translations.ReturnToUseCase} onClick={goToUseCaseOpen} style={{ marginTop: "20px" }} />
                }
            </HomeButtonsContainer>
        </HomeContainer>
    );
}

export default Home;
